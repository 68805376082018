<template>
  <v-card>
    <v-card-title>
      {{$t('generic.lang_addDeliveryArea')}}
    </v-card-title>
    <v-divider class="pa-0 ma-0"/>
    <v-container>
      <v-form v-model="valid" ref="form">
        <v-row>

          <v-col cols="12" sm="4">
            <v-text-field outlined
                          @focus="showTouchKeyboard"
                          :data-layout="KEYBOARD.KEYSETS.NORMAL"
                          v-model="zip"
                          :rules="[v=>(!!v )|| $t('generic.lang_requiredField')]"
                          :label="$t('generic.lang_zip')"
                          autocomplete="off"
                          :loading="loading"
                          :disabled="loading"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field outlined
                          @focus="showTouchKeyboard"
                          :data-layout="KEYBOARD.KEYSETS.NORMAL"
                          v-model="minOrder"
                          :rules="[v=>(parseInt(Number(v))>=0)|| $t('generic.lang_requiredField')]"
                          type="number"
                          :label="$t('erp.lang_deliveryMinimumOrder')"
                          autocomplete="off"
                          :loading="loading"
                          :disabled="loading"
                          :suffix="$store.getters['settings/currencyData']('symbol')"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field outlined
                          @focus="showTouchKeyboard"
                          :data-layout="KEYBOARD.KEYSETS.NORMAL"
                          v-model="cost"
                          :rules="[v=>(parseInt(Number(v))>=0)|| $t('generic.lang_requiredField')]"
                          type="number"
                          :label="$t('erp.lang_Delivery_DeliverySurcharge')"
                          autocomplete="off"
                          :loading="loading"
                          :disabled="loading"
                          :suffix="$store.getters['settings/currencyData']('symbol')"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field outlined
                          @focus="showTouchKeyboard"
                          :data-layout="KEYBOARD.KEYSETS.NORMAL"
                          v-model="motorcycleDeliveryTime"
                          :rules="[v=>(parseInt(Number(v))>=0)|| $t('generic.lang_requiredField')]"
                          type="number"
                          :label="$t('delivery.lang_motorcyleDeliveryTime')"
                          autocomplete="off"
                          :loading="loading"
                          :disabled="loading"
                          prepend-inner-icon="mdi-moped"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field outlined
                          @focus="showTouchKeyboard"
                          :data-layout="KEYBOARD.KEYSETS.NORMAL"
                          v-model="carDeliveryTime"
                          :rules="[v=>(parseInt(Number(v))>=0)|| $t('generic.lang_requiredField')]"
                          type="number"
                          :label="$t('delivery.lang_carDeliverTime')"
                          autocomplete="off"
                          :loading="loading"
                          :disabled="loading" prepend-inner-icon="mdi-car-sports"
            ></v-text-field>
          </v-col>
      </v-row>
      </v-form>
      <v-row no-gutters>
        <v-col cols="12" align="end">
          <v-btn
              color="primary"
              :loading="loading"
              :disabled="!valid"
              @click="create"
          >
            {{$t('generic.lang_add')}}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard id="onScreenKeyboard" class="internalWidth" :options="touchKeyboard.options"
                          :defaultKeySet="touchKeyboard.keySet" v-if="touchKeyboard.visible"
                          :layout="touchKeyboard.layout" :cancel="hideTouchKeyboard"
                          :accept="hideTouchKeyboard" :input="touchKeyboard.input"/>
    </div>
  </v-card>
</template>

<script>
import mixin from '../../../mixins/KeyboardMixIns'
import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";

export default {
  name: "DeliveryAreaCreate",
  components:{},
  mixins:[mixin],
  data(){
    return{
      ENDPOINTS,
      valid:false,
      loading:false,
      zip:null,
      cost:null,
      minOrder:null,
      motorcycleDeliveryTime:null,
      carDeliveryTime:null,
    }
  },
  methods:{
    create(){
      this.loading=true;
      if(!this.$refs.form.validate()){
        return;
      }

      this.axios.post(ENDPOINTS.DELIVERY.SETTINGS.DELIVERYAREA.CREATE,{
        zipCode:parseInt(this.zip),
        minOrder:Number(this.minOrder).toFixed(2),
        deliveryCost:Number(this.cost).toFixed(2),
        motorcycleDeliveryTime:this.motorcycleDeliveryTime,
        carDeliveryTime:this.carDeliveryTime,
      }).then(res=>{
        if(res.data.status==="SUCCESS"){
          this.$refs.form.reset();

          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_savedSuccessfully'),
            color: "success"
          });
        }else{
          Events.$emit("showSnackbar", {
            message: res.data.msg||res.data.status,
            color: "error"
          });
        }
      }).catch(err=>{
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(fin=>{
        this.loading=false;
      })

    }
  }
}
</script>

<style scoped>

</style>
